@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-gray-light;
}

.container {
  @apply max-w-7xl mx-auto px-4 sm:px-6 lg:px-8;
}

/* Custom transition classes */
.transition-colors {
  @apply transition-all duration-200 ease-in-out;
}

.transition-shadow {
  @apply transition-all duration-200 ease-in-out;
}

/* Custom hover states */
.hover\:bg-primary-dark:hover {
  @apply bg-opacity-90;
}
